<template>
  <!-- Loading spinner -->
  <div v-if="isLoading" class="center-vertical center-horizontal">
    <v-progress-circular
      indeterminate
      color="info"
      size="80"
      width="5"
    />
  </div>
  <!-- Error display -->
  <div v-else-if="!validRegistrationKeyFound" class="center-vertical center-horizontal">
    <v-icon
      color="error"
      size="80"
    >
      mdi-close-thick
    </v-icon>
  </div>
  <!-- Register card -->
  <v-card
    v-else
    class="mx-auto limited-width"
    elevation="4"
  >
    <!-- Rotec logo, not shown on mobile -->
    <v-img
      contain
      class="rotec-logo d-none d-sm-flex"
      alt="Rotec logo"
      src="@/assets/rotec-logo.png"
    />

    <v-form>
      <v-container>
        <!-- Expiry text -->
        <p
          v-if="expiryDateText"
          :class="[isExpired ? 'error--text' : 'text--secondary']"
          class="text-center"
        >
          <span v-if="isExpired">{{ $t('register.linkExpiredError', { time: expiryDateText }) }}</span>
          <span v-else>{{ $t('register.linkExpiresIn', { time: expiryDateText }) }}</span>
        </p>

        <!-- Email -->
        <v-text-field
          v-model="emailAddress"
          append-icon="mdi-account"
          :label="$t('register.email-address-label')"
          disabled
        />
        <!-- Password field -->
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          :label="$t('register.password-label')"
          autocomplete="off"
          autofocus
          @click:append="showPassword = !showPassword"
          :error-messages="validationErrors['password']"
          :error-count="5"
        />
        <!-- Repeat password field -->
        <v-text-field
          v-model="repeatPassword"
          :append-icon="showRepeatPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showRepeatPassword ? 'text' : 'password'"
          :label="$t('register.repeat-password-label')"
          autocomplete="off"
          @click:append="showRepeatPassword = !showRepeatPassword"
          :error-messages="validationErrors['repeatPassword']"
          :error-count="1"
        />

        <!-- Register button -->
        <v-btn
          :disabled="!enableRegisterButton"
          :loading="isBusyRegistering"
          block
          large
          color="primary"
          @click="tryRegister"
        >
          {{ $t('register.register-button') }}
        </v-btn>
      </v-container>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios'
import moment from 'moment-timezone'
import { mapActions } from 'vuex'

export default {
  data () {
    return {
      isLoading: true,
      isBusyRegistering: false,
      validRegistrationKeyFound: false,
      emailAddress: '',
      password: '',
      repeatPassword: '',
      expiryDate: null,
      showPassword: false,
      showRepeatPassword: false,
      validationErrors: {},
      nowUpdateTimer: null,
      now: moment()
    }
  },
  computed: {
    /**
     * The registration key in the url.
     * @returns {String} A (jwt hopefully) registration key.
     */
    registrationKey () {
      return this.$route.params.registrationKey
    },
    enableRegisterButton () {
      return this.password.length > 0 && this.repeatPassword.length > 0
    },
    isExpired () {
      return this.expiryDate ? this.now.isAfter(moment(this.expiryDate)) : false
    },
    expiryDateText () {
      return this.expiryDate ? moment(this.expiryDate).from(this.now) : null
    }
  },
  methods: {
    ...mapActions('snackbar', ['showSnackbar']),
    isNumber (value) {
      return typeof value === 'number'
    },
    getDisplayText (error) {
      return this.isNumber(error) ? this.$t(`errors.for-code-${error}`) : error
    },
    async tryRegister () {
      this.isBusyRegistering = true
      this.validationErrors = {}

      const payload = {
        registrationKey: this.registrationKey,
        password: this.password,
        repeatPassword: this.repeatPassword
      }
      try {
        await axios.post('userRegistrations/confirm', payload)

        // Show success message and wait for a few seconds.
        this.showSnackbar({
          role: 'success',
          messages: [this.$t('register.registration-success')]
        })
        await new Promise((resolve) => setTimeout(resolve, 5000))

        // Reroute to login screen.
        this.$router.push({ name: 'Login' })
      } catch (error) {
        if (error.response && error.response.status === 429) {
          // Action occurred too fast.
          this.showSnackbar({
            role: 'warn',
            messages: [this.$t('errors.for-code-10004')]
          })
        } else if (
          error.response &&
          error.response.status === 400 &&
          error.response.data &&
          error.response.data.errors
        ) {
          if (Array.isArray(error.response.data.errors)) {
            this.showSnackbar({
              role: 'error',
              messages: error.response.data.errors.map(this.getDisplayText),
              duration: 10000
            })
          } else {
            this.validationErrors = error.response.data.errors
          }
        } else {
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('register.registration-failed-unknown-reason')],
            duration: 10000
          })
        }
      } finally {
        this.isBusyRegistering = false
      }
    },
    async loadData () {
      this.isLoading = true
      try {
        const response = await axios.get(`userRegistrations/get-by-key/${this.registrationKey}`)
        this.validRegistrationKeyFound = true
        this.emailAddress = response.data.emailAddress
        this.expiryDate = response.data.expiryDate
      } catch (error) {
        this.validRegistrationKeyFound = false
        if (error.response.status === 429) {
          // Action occurred too fast.
          this.showSnackbar({
            role: 'warn',
            messages: [this.$t('errors.for-code-10004')]
          })
        } else if (error.response.status === 404) {
          // Registration key invalid or expired.
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('errors.registration-key-invalid-or-expired')]
          })
        } else {
          // Other errors.
          this.showSnackbar({
            role: 'error',
            messages: [this.$t('errors.loading-data-failed')]
          })
        }
      } finally {
        this.isLoading = false
      }
    }
  },
  async mounted () {
    await this.loadData()
    // Start a timer that updates the current time every second,
    // because vue computeds can't react to time changes.
    this.nowUpdateTimer = setInterval(() => {
      this.now = moment()
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.nowUpdateTimer)
  }
}
</script>

<style lang="scss" scoped>
.center-vertical {
  margin-top: 40vh;
}

.center-horizontal {
  display: flex;
  justify-content: center;
}

.rotec-logo {
  height: 7rem;
}

.limited-width {
  max-width: 400px;
}
</style>
